// $color-primary: #5379ff;
$color-primary: #ff6d24;
$color-light1: #f4f4f4;

$color-border: '#dde2e8';

@import './tabs.scss';
@import './modal.scss';

.ant-form-item-label {
  font-weight: 500;
}

a {
  color: $color-primary;
}

/*
 *****************************************
 * Button
 *
 */

.ant-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:disabled {
    background-color: $color-light1;
    border-color: $color-light1;
  }
}

/*
 *****************************************
 * Input
 *
 */

input.ant-input {
  box-shadow: none !important;

  &:hover {
    border-color: $color-border !important;
  }

  &:focus {
    border-color: $color-primary !important;
  }

  &:read-only {
    background-color: $color-light1 !important;

    &:hover {
      border-color: $color-border !important;
    }

    &:focus {
      border-color: $color-light1 !important;
    }
  }
}

/*
 *****************************************
 * Form
 *
 */

.ant-form-item-control {
  .ant-form-item-explain {
    font-weight: 400;
  }
}

.zo-popover {
  .ant-popover-inner {
    padding: 0 !important;
    overflow: hidden;
  }
}

.ant-tooltip-inner {
  min-height: auto !important;
}

/*
 ***********
 * Alert
 *
 */

.ant-popconfirm {
  .ant-popconfirm-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
