.tnote {
  [data-content-type="heading"][data-level="1"] {
    font-size: 30px !important;
    margin-top: 16px;
    padding-top: 16px;
  }

  [data-content-type="heading"][data-level="2"] {
    font-size: 24px !important;
    margin-top: 12px;
    padding-top: 10px;
  }

  [data-content-type="heading"][data-level="3"] {
    font-size: 20px !important;
    margin-top: 16px;
  }

  [data-content-type="paragraph"] {
    margin-top: 1px;
  }

  ::selection {
    background-color: #2383e247;
  }
}