.zo-base-flat-input {
  position: relative;

  input {
    outline: none;
    padding: 8px 0 8px 0;
    font-size: 16px;
    background-color: transparent;
    width: 100%;
  }

  &:focus-within {
    background-color: #ffffff;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -8px;
      width: 8px;
      background-color: #ffffff;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: -8px;
      width: 8px;
      background-color: #ffffff;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}

.zo-base-flat-input-no-style {
  position: relative;

  input {
    outline: none;
    padding: 8px 0 8px 0;
    font-size: 16px;
    background-color: transparent;
    width: 100%;
  }
}
