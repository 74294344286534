@tailwind base;
@tailwind components;
@tailwind utilities;

@import './components/input';

@import './antd/index';

@import './scrollbar';
@import './react-contexify/main';

@import './blockquote/styles';

html {
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0;
  font-size: 15px;
  line-height: 24px;
  font-family: 'Inter', sans-serif;
}

* {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #dde2e8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #111827;
}

main {
  min-height: calc(100vh);
}